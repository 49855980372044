.Distributor-page{
    display: inline-block;
    position: relative;
    width: 1140px;
}
.Distributor-page .Distributor-app .Distributor-nav{
    display: flex;
    flex-wrap: wrap;
    padding: 70px 0px 40px 0px;
    gap: 15px;
}
.Distributor-page .Distributor-app .Distributor-content{
    border-top: solid 1px #E3E3E3;
    padding: 60px 3px 30px 3px;
    min-height: 400px;
}
.Distributor-page .Distributor-app .Distributor-nav .Distributor-nav-button{
    display: flex;
    align-items: center;
    gap: 10px;
    height: 49px;
    padding: 0px 27px 0px 27px;
    background-color: #4C187F;
    border-radius: 23px;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}
.Distributor-page .Distributor-app .Distributor-nav .Distributor-nav-button span{
    color: #EBEBEB;
    font-size: 15px;
    font-family: inherit;
    font-weight: 300;
}
.Distributor-page .Distributor-app .Distributor-nav .Distributor-nav-button:hover{
    background-color: #EC6C3A;
}










.Distributor-page .Distributor-app .Distributor-content .Company-list{
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}
.Distributor-page .Distributor-app .Distributor-content .Company-list .Distributor-content-footer,
.Distributor-page .Distributor-app .Distributor-content .Material-page .Distributor-content-footer{
    display: flex;
    width: 100%;
    padding: 20px 0px 0px 0px;
    justify-content: right;
}
.Distributor-page .Distributor-app .Distributor-content .Company-list .Distributor-content-footer button,
.Distributor-page .Distributor-app .Distributor-content .Material-page .Distributor-content-footer button{
    font-family: inherit;
    background-color: #ec6c3a;
    font-size: 15px;
    border: none;
    border-radius: 23px;
    color: #ebebeb;
    cursor: pointer;
    padding: 13px 30px;
    margin: auto 0px 0px 0px;
    transition: all 0.3s ease-in-out;
}
.Distributor-page .Distributor-app .Distributor-content .Company-list .Distributor-content-footer button:hover,
.Distributor-page .Distributor-app .Distributor-content .Material-page .Distributor-content-footer button:hover{
    background-color: #F25416;
}
.Distributor-page .Distributor-app .Distributor-content .Company-card{
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: left;
    gap: 25px;
    padding: 20px;
    background-color: white;
    border-radius: 20px;
    box-shadow: 1px 1px 1px 0px #00000029;
    width: 368px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}
.Distributor-page .Distributor-app .Distributor-content .Company-card:hover{
    background-color: #F2F1FE;
}
.Distributor-page .Distributor-app .Distributor-content .Company-card h2{
    display: block;
    font-size: 30px;
    width: calc(100% - 10px);
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #4C187F;
    margin: 0;
}
.Distributor-page .Distributor-app .Distributor-content .Company-card .Company-details{
    display: flex;
    position: relative;
    gap: 10px;
    flex-direction: column;
}
.Distributor-page .Distributor-app .Distributor-content .Company-card .Company-details p{
    display: flex;
    color: #4C187F;
    margin: 0;
}
.Distributor-page .Distributor-app .Distributor-content .Company-card .Company-details::after{
    content: '';
    position: absolute;
    height: 1px;
    width: 30px;
    top: -15px;
    left: 0;
    background-color: #EC6C3A;
}










.Distributor-page .Distributor-app .Distributor-content .Company-page{
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: left;
    gap: 20px;
    background-color: white;
    border-radius: 20px;
    box-shadow: 1px 1px 1px 0px #00000029;
    padding: 40px 40px 40px 40px;
}
.Distributor-page .Distributor-app .Distributor-content .Company-page .Company-page-actions{
    position: absolute;
    top: calc(40px);
    left: calc(100% - 40px);
    transform: translate(-100%, 50%);
}
.Distributor-page .Distributor-app .Distributor-content .Company-page .Company-page-actions img{
    cursor: pointer;
}
.Distributor-page .Distributor-app .Distributor-content .Company-page h2{
    display: block;
    font-size: 35px;
    width: calc(100% - 10px);
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #4C187F;
    margin: 0;
}
.Distributor-page .Distributor-app .Distributor-content .Company-page .Company-tab a{
    display: block;
    position: relative;
    text-align: left;
    font-family: inherit;
    font-size: 20px;
    color: #4C187F;
    border-bottom: 1px solid #EC6C3A;
    cursor: pointer;
}
.Distributor-page .Distributor-app .Distributor-content .Company-page .Company-tab a img{
    position: absolute;
    left: calc(100% - 24px);
    top: calc(50% - 12px);
    transition: transform 0.3s ease-in-out;
}
.Distributor-page .Distributor-app .Distributor-content .Company-page .Company-tab.active{
    z-index: 1;
}
.Distributor-page .Distributor-app .Distributor-content .Company-page .Company-tab.active a img{
    transform: rotateX(180deg);
}
.Distributor-page .Distributor-app .Distributor-content .Company-page .Company-tab .Company-tab-content{
    text-align: left;
    transform: scaleY(0);    
    transform-origin: top;
    transition: all 0.3s ease;
    height: 0;
}
.Distributor-page .Distributor-app .Distributor-content .Company-page .Company-tab.active .Company-tab-content{
    transform: scaleY(1);
    padding: 40px 0px;
    height: auto;
}
.Distributor-page .Distributor-app .Distributor-content .Company-page .Company-tab.active .Company-tab-content .Material-select{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    margin-top: 40px;
}
.Distributor-page .Distributor-app .Distributor-content .Company-page .Company-tab.active .Company-tab-content .Material-select button{
    font-family: inherit;
    background-color: #ec6c3a;
    font-size: 15px;
    border: none;
    border-radius: 23px;
    color: #ebebeb;
    cursor: pointer;
    padding: 13px 30px;
    margin: auto 0px 0px 0px;
    transition: all 0.3s ease-in-out;
}
.Distributor-page .Distributor-app .Distributor-content .Company-page .Company-tab.active .Company-tab-content .Material-select .File-select-container{
    display: inline-block;
    width: 230px;
    text-align: left;
    cursor: pointer;
}
.Distributor-page .Distributor-app .Distributor-content .Company-page .Company-tab .Company-tab-content table{
    table-layout: fixed;
    border-spacing: 0;
    width: 100%;
    color: #4C187F;
}
.Distributor-page .Distributor-app .Distributor-content .Company-page .Company-tab .Company-tab-content table th,
.Distributor-page .Distributor-app .Distributor-content .Company-page .Company-tab .Company-tab-content table tbody td{
    vertical-align: middle;
    padding: 0px;
    text-align: left;
    word-break: break-word;
    border-bottom: 1px solid lightgray;
}
.Distributor-page .Distributor-app .Distributor-content .Company-page .Company-tab .Company-tab-content table tfoot td{
    vertical-align: middle;
    padding: 0px;
}
.Distributor-page .Distributor-app .Distributor-content .Company-page .Company-tab .Company-tab-content table tr{
    height: 50px;
}
.Distributor-page .Distributor-app .Distributor-content .Company-page .Company-tab .Company-tab-content table .Company-user-list-actions,
.Distributor-page .Distributor-app .Distributor-content .Company-page .Company-page-actions,
.Distributor-page .Distributor-app .Distributor-content .Company-page .Company-tab .Company-tab-content table .Material-list-actions{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 5px;
}
.Distributor-page .Distributor-app .Distributor-content .Company-page .Company-tab .Company-tab-content table .Company-user-status{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 10px;
}
.Distributor-page .Distributor-app .Distributor-content .Company-page .Company-tab .Company-tab-content table img{
    cursor: pointer;
}









.Distributor-page .Distributor-app .Distributor-content .Material-page table{
    table-layout: fixed;
    border-spacing: 0;
    width: 100%;
    color: #4C187F;
}
.Distributor-page .Distributor-app .Distributor-content .Material-page table tbody tr{
    height: 50px;
}
.Distributor-page .Distributor-app .Distributor-content .Material-page table thead th{
    padding: 15px;
}
.Distributor-page .Distributor-app .Distributor-content .Material-page table th,
.Distributor-page .Distributor-app .Distributor-content .Material-page table tbody td{
    vertical-align: middle;
    padding: 0px;
    text-align: left;
    word-break: break-word;
}
.Distributor-page .Distributor-app .Distributor-content .Material-page table .Material-list-parameter{
    background-color: white;
    padding: 10px 15px;
    margin-bottom: 8px;
    border-bottom: 1px solid #E3E3E3;
}
.Distributor-page .Distributor-app .Distributor-content .Material-page table .Material-list-actions{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    background-color: white;
    border-bottom: 1px solid #E3E3E3;
    gap: 5px;
    height: 42px;
    padding: 0px 15px 0px 0px;
    margin-bottom: 8px;
}
.Distributor-page .Distributor-app .Distributor-content .Material-page table img{
    width: 23px;
    height: 23px;
    cursor: pointer;
}