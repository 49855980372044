*{
	box-sizing: border-box;
}
.Login-logo{
    width: 76px;
    margin-bottom: 25px;
    margin-top: 25px;
}
.Login-header{
    width: 200px;
    font-family: inherit;
    font-size: 30px;
    line-height: 35px;
    color: #4c187f;
    margin: 0px auto 80px auto;
}
.Login-form{
    margin: 60px auto 60px auto;
    width: 360px;
    height: 620px;
    padding: 30px 30px 60px 30px;
    background-color: #fff;
    border-radius: 19px;
}
.Login-form form{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
}
.Login-form .Input-password-strength{
    position: relative;
    width: 100%;
    color: #4c187f;
    text-align: left;
}
.Login-form .Input-password-strength-meter{
    position: absolute;
    top: -10px;
    left: 0;
    width: 100%;
    background-color: #C9C9C9;
    border-radius: 3px;
    height: 3px;
}
.Login-form .Input-password-strength-meter-progress{
    content: '';
    position: absolute;
    height: 3px;
}
.Login-form .Input-password-strength-message{
    font-size: 12px;
}
.Login-form .Input-wrapper{
    position: relative;
    margin-bottom: 15px;
}
.Login-form .Input-icon{
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
}
.Login-form .Input-wrapper input{
    font-family: inherit;
    font-size: 14px;
    width: 300px;
    padding: 14px 0px 14px 55px;
    color: #4c187f;
    border: solid 1px #c9c9c9;
    border-radius: 5px;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus {
  -webkit-text-fill-color: #4c187f;
  -webkit-box-shadow: 0 0 0px 40rem #fff inset;
}
.Login-form .Input-wrapper input:focus-visible{
    outline: none;
}
.Login-form button{
    font-family: inherit;
    background-color: #ec6c3a;
    font-size: 15px;
    border: none;
    border-radius: 23px;
    color: #ebebeb;
    cursor: pointer;
    padding: 13px 30px;
    margin: auto 0px 0px 0px;
}