.App-popup{
    z-index: 10;
}
.App-popup, .overlay{
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
}

.overlay{
    background-color: rgba(0,0,0,0.7)
}
.popup-content{
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 20px;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: white;
    color: #4c187f;
    padding: 30px 50px;
    border-radius: 23px;
    max-width: 600px;
    min-width: 300px;
}
.popup-content h2{
    margin: 0;
}
.popup-content form{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.popup-content .popup-input,
.popup-content .popup-checkbox{
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}
.popup-content .popup-input label,
.popup-content .popup-checkbox label{
    min-width: 120px;
    text-align: left;
    flex: 0 0 auto;
}
.popup-content .popup-input input{
    font-family: inherit;
    font-size: 14px;
    width: 100%;
    min-width: 200px;
    padding: 10px;
    color: #4c187f;
    border: solid 1px #c9c9c9;
    border-radius: 5px;
}
.popup-content .popup-checkbox input{
    margin: 0;
    accent-color: #4C187F;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus {
  -webkit-text-fill-color: #4C187F;
  -webkit-box-shadow: 0 0 0px 40rem #fff inset;
}
.popup-content .popup-input input:focus-visible{
    outline: none;
}
.popup-content .popup-upload{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.popup-content .popup-upload input{
    display: none;
}
.popup-content .popup-upload label{
    display: block;
    width: 220px;
    font-size: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border: 1px solid #EC6C3A;
    border-radius: 20px;
    line-height: 17.5px;
    padding: 13px 30px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}
.popup-content .popup-upload label:hover{
    color: #EC6C3A;
}
.popup-buttons{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 15px;
}
.popup-buttons:has(button:nth-child(2)){
    justify-content: center;
}
.popup-buttons button{
    font-family: inherit;
    background-color: #ec6c3a;
    font-size: 15px;
    border: none;
    border-radius: 23px;
    color: #ebebeb;
    cursor: pointer;
    padding: 13px 30px;
    margin: auto 0px 0px 0px;
    transition: all 0.3s ease-in-out;
}
.popup-buttons button:hover{
    background-color: #F25416;
}
.close-popup{
    position: absolute;
    top: 10px;
    right: 15px;
    cursor: pointer;
    font-size: 20px;
    border: none;
    background: none;
    color: #4c187f;
    transition: 0.3 color ease-in-out;
}
.close-popup:hover{
    color: #ec6c3a;
}