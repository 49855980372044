.App-tooltip
{
    position: relative;
    display: flex;
}
.App-tooltip-text
{
    position: absolute;
    box-sizing: border-box;
    text-align: center;
    top: calc(-100% - 30px);
    left: 50%;
    transform: translateX(-50%);
    background-color: #F5F5F5;
    border: 1px solid #EC6C3A;
    color: #4C187F;
    padding: 10px;
    border-radius: 5px;
    min-width: 180px;
}
.App-tooltip-text::before,
.App-tooltip-text::after
{
    content: '';
    position: absolute;
    top: calc(100% + 4px);
    left: 50%;
    transform: translate(-50%, -50%);
    border-top: 8px solid #F5F5F5;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;        
    border-bottom: 0;  
}
.App-tooltip-text::before{
    border-top-color: #EC6C3A;
    top: calc(100% + 5px);
}