*{
	box-sizing: border-box;
}
body{
	background: #f5f5f5;
	padding: 0px;
	margin: 0px;
	font-family: 'Lufga';
	font-size: 16px;
	text-align: center;
}
.hide-overflow{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0px 10px 0px 0px;
}
.App {
  text-align: center;
}
.App-header {
	width: 100%;
}
.App-header-top {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 65px;
	background-color: #F2F1FE;
}
.App-header-bottom {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 80px;
	background-color: #4C187F;
}
.App-header-bottom .App-header-logo{
	display: inline;
}
.App-header-bottom .App-header-logo img{
	vertical-align: middle;
	cursor: pointer;
}
.App-header-bottom .App-header-menu{
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	margin: 0px 0px 0px auto;
	gap: 30px;
}
.App-header-bottom .App-header-menu a{
	font-family: inherit;
	font-size: 15px;
	font-weight: 400;
	color: #EBEBEB;
	padding: 5px 0px;
	text-decoration: none;
	border-bottom: 1px solid #00000000;
	transition: all 0.3s ease-in-out;
}
.App-header-bottom .App-header-menu a:hover{
	color: #EC6C3A;
	border-bottom-color: #EC6C3A;
}
.App-inner {
	display: flex;
	width: 1140px;
}
.App-header-top .App-inner {
	flex-direction: row;
	justify-content: flex-end;
	gap: 14px;
}
.App-header-top .App-inner button{
	position: relative;
	font-family: inherit;
	font-size: 15px;
	font-weight: 300;
	line-height: 19px;
	padding: 0px;
	color: #4C187F;
	background-color: #00000000;
	border: none;
	cursor: pointer;
}
.App-header-top .App-inner button::after{
	content: '';
	position: absolute;
	background-color: #4C187F;
	height: 100%;
	width: 1px;
	left: -7px;
}
.App-header-top .App-inner p{
	font-family: inherit;
	font-size: 15px;
	font-weight: 300;
	line-height: 19px;
	margin: 0px auto 0px 0px;
	color: #4C187F;
}
.App-footer{
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #4C187F;
	min-height: 450px;
}
.App-footer .App-inner{
	padding: 40px 0px 20px 0px;
	flex-wrap: wrap;
	height: 100%;
}
.App-footer .App-footer-left{
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 30px;
	width: 485px;
}
.App-footer .App-footer-right{
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
	gap: 30px;
	padding-top: 85px;
	width: calc(100% - 485px);
}
.App-footer-bottom{
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: auto 0px 0px 0px;
	width: 100%;
}
.App-footer .App-footer-left .App-footer-desc{
	width: 345px;
	font-size: 20px;
	text-align: left;
	color: #F5F5F5;
	line-height: 1.8em;
	margin: 0;
}
.App-footer .App-footer-right .App-footer-sub-menu{
	text-align: left;
}
.App-footer .App-footer-right h2{
	position: relative;
	font-size: 14px;
	font-weight: 600;
	line-height: 22px;
	margin: 0;
	padding: 0px 0px 5px 0px;
	color: #F5F5F5;
}
.App-footer .App-footer-right h2::after{
	content: '';
	position: absolute;
	width: 40px;
	height: 1px;
	left: 0px;
	top: 100%;
	background-color: #EC6C3A;
}
.App-footer .App-footer-right p,
.App-footer .App-footer-right ul{
	margin: 10px 0px 0px 0px;
	font-size: 12px;
	font-weight: 300;
	line-height: 22px;
	color: #F5F5F5;
}
.App-footer .App-footer-right a{
	color: inherit;
	text-decoration: none;
}
.App-footer .App-footer-right ul{
	list-style: none;
	padding: 0;
}
.App-footer-bottom .App-footer-bottom-left{
	font-size: 10px;
	font-weight: 300;
	text-align: left;
	color: #F5F5F5;
	width: 485px;
}
.App-footer-bottom .App-footer-bottom-right{
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 12px;
	font-weight: 300;
	text-align: left;
	color: #F5F5F5;
	width: calc(100% - 485px);
}
.App-footer-bottom .App-footer-bottom-right .App-psnm-logo{
	height: 16px;
}
.App-footer-bottom .App-footer-bottom-right .App-pire-logo{
	height: 44px;
	padding-bottom: 7px;
}